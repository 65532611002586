import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Lead } from 'src/app/shared/types/lead.types';
import { environment } from 'src/environments/environment';
import { LeadType } from '../types/lead-types.types';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  constructor(private http: HttpClient) {}

  public getAllLeads(params: HttpParams) {
    return this.http.get<Lead[]>(environment.apiUrl + '/leads/', { params: params });
  }

  /**
   * Returns submissions
   * @returns leadsData
   */
  public getLeadData(model_id: string) {
    return this.http.get<Lead>(environment.apiUrl + `/leads/${model_id}/`);
  }

  public updateLead(leadId: string, updatedlead: Partial<Lead>) {
    return this.http.patch<Lead>(environment.apiUrl + `/leads/${leadId}/`, updatedlead);
  }

  /**
   * Returns LeadTypes semaphor
   * @returns leadTypes
   */
  public getLeadTypes(): Observable<LeadType[]> {
    return this.http.get<LeadType[]>(environment.apiUrl + '/lead-types/');
  }
}
